document.addEventListener("DOMContentLoaded", function () {
  mobileMenu();
  contactTabs();
  searchModal();
  customTeamModal();
  getTeamInfo();
  gallerySlides();
});

// Toggle main menu on mmobile.
function mobileMenu() {
  let el = document.querySelectorAll(".menu-toggle");
  for (var i = 0; i < el.length; i++) {
    el[i].addEventListener("click", function () {
      document.querySelector(".menu-bg").classList.toggle("active");
      document.body.classList.toggle("menu-active");
      document.getElementById("mobileNav").classList.toggle("active");
    });
  }
}

// Modal
function setModalBehavior(modalId, triggerClass, closeModalClass) {
  const modal = document.querySelector(modalId);
  if (modal) {
    const modalWrapper = modal.getElementsByClassName("modal-container")[0];
    const openBtns = document.querySelectorAll(`.${triggerClass}`);
    const close = modal.getElementsByClassName("close")[0];

    function toggleModal(status) {
      if (status) {
        modal.style.display = "flex";
        document.body.classList.add("modal-active");
      } else {
        modal.style.display = "none";
        document.body.classList.remove("modal-active");
      }
    }

    openBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        toggleModal(true);
      });
    });

    close.addEventListener("click", () => {
      toggleModal(false);
    });

    window.addEventListener("click", (event) => {
      if (event.target === modalWrapper) {
        toggleModal(false);
      }
    });
  }
}
function searchModal(status = false) {
  setModalBehavior("#searchModal", "search-button", "close");
}

function customTeamModal(status = false) {
  setModalBehavior("#teamModal", "team__card", "close");
}

// Tabs
function contactTabs() {
  const tabsButtons = document.querySelectorAll(".contacts-map__top--button");

  function showTab(tabIndex) {
    const tabs = document.querySelectorAll(".contacts-map__content--tab");

    tabsButtons.forEach((button, index) => {
      const tab = tabs[index];

      if (index === tabIndex) {
        button.classList.add("active");
        tab.classList.add("show");
      } else {
        button.classList.remove("active");
        tab.classList.remove("show");
      }
    });
  }

  if (tabsButtons.length > 0) {
    tabsButtons.forEach((button, index) => {
      button.addEventListener("click", () => {
        showTab(index);
      });
    });
    showTab(0);
  }
}

// Get team info
function getTeamInfo() {
  document.querySelectorAll(".team__card").forEach(function (element) {
    element.addEventListener("click", function () {
      fetch(sthm_ajax_params.ajaxurl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: new URLSearchParams({
          post_id: this.getAttribute("data-member"),
          action: "get_member",
        }),
        beforeSend: function (xhr) {},
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.thumbnail);
          customTeamModal(true);
          if (data.thumbnail) {
            document.getElementById("memberPhoto").src = data.thumbnail;
          } else {
            document.getElementById("memberPhoto").src = "";
          }
          if (data.title) {
            document.getElementById("memberTitle").innerHTML = data.title;
          } else {
            document.getElementById("memberTitle").innerHTML = "";
          }
          if (data.excerpt) {
            document.getElementById("memberPosition").innerHTML = data.excerpt;
          } else {
            document.getElementById("memberPosition").innerHTML = "";
          }
          if (data.content) {
            document.getElementById("memberContent").innerHTML = data.content;
          } else {
            document.getElementById("memberContent").innerHTML = "";
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
  });
}

function gallerySlides() {
  let galleryMedia = document.querySelectorAll(".gallery__media");
  if (galleryMedia) {
    galleryMedia.forEach((gallery) => {
      new Glide(gallery, {
        type: "carousel",
        gap: 0,
        autoplay: gallery.getAttribute("data-autoplay"),
        hoverpause: true,
        transitionType: "fade",
      }).mount();
    });
  }
}
